.serviceMainTitle{
    color: #4e3b1b;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-family: 'Montserrat',sans-serif;
    font-weight: 700;
    font-size: 28px;
}
.bottom{
    background: #4e3b1b none repeat scroll 0 0;
    height: 3px;
    width: 100px;
    margin: -1.9rem auto 0;
    margin-bottom: 3rem;
}
.aboutMeImage{
    width: 550px;
    height: 550px;
    background-color: #051b35;
    border-radius: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    float: right;
}
.aboutImg{
    height: 100%;
    margin-top: 210px;
}
.aboutMeBody{
    width: 100%;
    height: 100%;
    padding-left: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10rem;
}
.aboutMeTitle{
    color: #051b35;
    font-family: 'Montserrat',sans-serif;
    font-weight: 700;
    font-size: 40px;
}
.aboutMeDetails{
    color: #051b35;
    font-family: 'Raleway',sans-serif;
    font-weight: 600;
    font-size: 28px;
}
