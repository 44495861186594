.intro-area--top{
    margin-top: -70px;
    position: relative;
    z-index: 2;
}
.section-title {
    margin-bottom: 45px;
    position: relative;
}
.section-title .sub-title {
    font-weight: 500;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
}
.section-title .sub-title.double-line:before {
    content: "";
    position: absolute;
    left: -50px;
    top: 10px;
    height: 2px;
    width: 40px;
    background: #051b35;
}
.section-title .sub-title.double-line:after {
    content: "";
    position: absolute;
    right: -50px;
    top: 10px;
    height: 2px;
    width: 40px;
    background: #051b35;
}
.intro-area-inner {
    box-shadow: 0 0 30px rgb(0 0 0 / 7%);
    background: #fff;
    padding: 50px 50px;
    border-radius: 8px;
}

.maintitle{
    font-family: 'Montserrat',sans-serif;
    color: #051b35;
    font-size: 1.2rem;
}
.serviceName{
    color: #051b35;
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 24px;
    font-weight: 700;
}
.serviceDescription{
    color: #505050;
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 15px;
    font-weight: 400;

}

@media only screen and (max-width: 768px) {
    .intro-area--top {
        display: none;
    }
}

