/* ======== google fonts ======== */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");

/* ======= base style ====== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
}

/* ========= pre-define css ========= */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4e3b1b;
  font-weight: 600;
  margin-bottom: 0;
}

h3,
h4 {
  font-size: 1.6rem;
}

h5,
h6 {
  font-size: 1.2rem;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}
