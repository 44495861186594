.event__title {
    color: #f9a826;
    font-size: 1.2rem;
    font-weight: 600;
    transition: 0.3s;
}

.section__time {
    color: #e70808;
    font-weight: 900;
}

