.facultyMainTitle{
    color: #4e3b1b;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-family: 'Montserrat',sans-serif;
    font-weight: 700;
    font-size: 28px;
}
.bottom{
    background: #4e3b1b none repeat scroll 0 0;
    height: 3px;
    width: 100px;
    margin: -1.9rem auto 0;
    margin-bottom: 3rem;
}
.facultyCardDisplay {
    display: flex;
   justify-content: center;
}
.facultyCard{
    margin: 5px 5px 5px 5px;
    height: auto;
    padding: 60px 30px 60px 30px;
    box-shadow: 0 0 8px 0 rgb(4, 55, 105,0.2);
}
.facultyCard:hover{
    margin: 5px 5px 5px 5px;
    height: auto;
    padding: 60px 30px 60px 30px;
    box-shadow: 0 0 16px 0 rgb(4, 55, 105,0.2);
}
.facultyName{
    color: #051b35;
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 24px;
    font-weight: 700;
}
.facultyDescription{
    color: #4e3b1b;
    font-weight: bold;
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 15px;
    font-weight: 400;

}
.facultyMainTitle{
    color: #051b35;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-family: 'Montserrat',sans-serif;
    font-weight: 700;
    font-size: 28px;
}
.lawIcon{
    height: 80px;
    width: 80px;
}

  
.faculty__section-item p i {
    color: #f9a826;
    font-size: 1.3rem;
  }
  .faculty__section-item p  {
    font-size: 1.3rem;
    color: #4e3b1b;
  }
  .faculty__ul-section-item  {
    list-style: none;
  }
  .faculty__link {
    text-decoration: none;
    color: #4e3b1b;
    font-size: 1.2rem;
    font-weight: 600;
    transition: 0.3s;
  }
  .faculty__link:hover {
    color: #f9a826;
  }
  

  