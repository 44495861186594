.strengthsMainTitle{
    color: #4e3b1b;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-family: 'Montserrat',sans-serif;
    font-weight: 700;
    font-size: 28px;
    text-align: center;
}
.bottom{
    background: #4e3b1b none repeat scroll 0 0;
    height: 3px;
    width: 100px;
    margin: -1.9rem auto 0;
    margin-bottom: 3rem;
}
.strengthsBanner{
    background-image: url("../assets/img/strength-img.jpg");
    min-height: 400px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.strengthsBannerOverlay{
    position: absolute;
    min-height: 400px;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
}
.countSection{
    margin-top: 60%;
    border: 1px solid red;
}
.iconProject{
    color: #ffffff;
    font-size: 40px;
}

.intro-footer {
    padding: 30px 30px 10px;
    border-radius: 0 0 7px 7px;
}
.intro-footer h5 {
    color: #ffffff;
}

.sideImg{
    margin-top: -15px;
}
.homeIntro{
   font-family: 'Montserrat',sans-serif;
   font-weight: 700;
   color: #051b35;
   font-size: 16px;

}

@media only screen and (max-width: 768px) {
    .strengthsBanner{
        min-height: 950px;
    }
    .strengthsBannerOverlay{
        min-height: 950px;
    }
  }